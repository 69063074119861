@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;

.header {
  &--desktop {
    padding-bottom: $spacing-03;
  }

  &--scrolled {
    background-color: theme.$background;
    padding: 1rem 1rem 1rem 2rem;
  }

  &__title {
    color: theme.$text-primary;

    &--scrolled {
      font-size: 0.875rem;
      line-height: normal;
    }
  }

  &__date {
    @include type-style('body-short-01');
    color: theme.$text-secondary;
  }
}
