@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;

.box-graph-wrapper {
  padding: $spacing-05;
  background-color: theme.$layer-01;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 10rem;
  max-width: 15rem;
  flex-grow: 1;
  cursor: pointer;

  &__title,
  &__subtitle {
    @include type-style('label-01');
    color: theme.$text-primary;
  }

  &:hover {
    background-color: theme.$field-01;
  }
}

.pipeline-tooltip {
  max-width: 21.125rem;
  border-radius: 0.25rem;
  overflow: hidden;

  &__content {
    background-color: theme.$layer-01;
    padding-bottom: $spacing-05;
    color: theme.$text-primary;

    &__header {
      @include type-style('body-long-01');
      padding: $spacing-05 $spacing-05 0 $spacing-05;

      .title {
        @include type-style('body-short-02');
      }
      .date {
        @include type-style('label-01');

        &--before {
          color: theme.$support-warning;
        }
        &--today {
          color: theme.$icon-primary;
        }
      }
      .description {
        @include type-style('body-long-01');
        margin: $spacing-03 0 $spacing-05 0;
        white-space: pre-line;
      }
    }
    & .list-title {
      @include type-style('body-long-02');
      border-bottom: 1px solid theme.$background-hover;
      padding: 0 0 $spacing-03 0;
      white-space: nowrap;
    }
    &__list {
      list-style: none;
    }
  }
}

.tooltip-list {
  @include type-style('label-01');

  &__list-item {
    border-bottom: 1px solid theme.$background-hover;
    align-items: center;
    padding: $spacing-03 0;
    margin: 0 $spacing-05;
    display: flex;
    cursor: pointer;

    &__title {
      justify-content: space-between;
      margin-left: 0.4375rem;
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &:hover {
    background-color: theme.$background-hover;
  }
}
