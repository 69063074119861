@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/spacing' as *;

.data-tile-section {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: $spacing-02;
  color: theme.$text-primary;

  &--desktop:not(&--scrolled) {
    gap: $spacing-03;
  }

  &__content {
    display: flex;
    flex: 50%;
    flex-wrap: wrap;
    gap: $spacing-01;

    &-partition {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      gap: $spacing-01;
    }
  }
}
