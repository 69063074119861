@use '@carbon/react/scss/theme';

.box-graph-cell {
  background-color: theme.$layer-02;
  min-width: 0.438rem;
  max-width: 0.75rem;
  display: inline-flex;
  flex-grow: 1;
  flex-shrink: 1;
  aspect-ratio: 1 / 1;

  &--hold {
    background-color: theme.$support-warning;
  }

  &--not-hold {
    background-color: theme.$icon-primary;
  }

  &--indicator {
    background-color: theme.$icon-disabled;
  }
}
