@use '@carbon/react/scss/theme';

.data-tile-scrolled-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 0.125rem;
  width: 100%;
  background-color: theme.$layer-02;

  &__trailing-block {
    background-color: theme.$background;
  }

  &__accordion {
    padding: 0;

    & .cds--accordion__heading {
      background-color: theme.$background;
      border-radius: 0;
      transition: none;

      &:hover:before {
        background-color: theme.$background;
        border-radius: 0;
        top: 0;
        height: 100%;
      }

      & .cds--accordion__arrow {
        display: none;
      }
    }

    & .cds--accordion__heading[aria-expanded='true'] {
      background-color: theme.$background;
      border-radius: 0;
      border: none;

      &:hover:before {
        background-color: theme.$background;
        border-radius: 0;
      }
    }

    & .cds--accordion__item--active .cds--accordion__content {
      padding: 0;
    }

    & .cds--css-grid {
      padding: 0;
    }
  }
}
