@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;

.data-tile {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: $spacing-07;
  min-width: 9rem;
  padding: $spacing-05;
  background-color: theme.$background;
  cursor: pointer;

  &:hover {
    background-color: theme.$background-hover;
  }

  &__title {
    @include type-style('label-01');
    white-space: nowrap;
    flex: 1;
  }

  &__content {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;

    svg,
    img {
      margin-top: 0.35rem;
    }

    &-total {
      font-size: 1.5rem;
      font-weight: normal;
      line-height: 2rem;

      &--scrolled {
        @include type-style('body-short-02');
      }
    }

    &-icon {
      &--warning {
        fill: theme.$support-warning;
        color: theme.$support-warning;
      }
    }
  }
}
