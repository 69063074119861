@use './styles/colors';

@use '@carbon/react/scss/themes' as *;
@use '@carbon/react/scss/theme' with (
  $theme: (
    // alert tokens
    alert-success: colors.$success50,
    alert-warning: colors.$warning50,
    alert-error: colors.$error50,
    alert-information: colors.$info50,

    // layer / paper tokens
    layer-01: colors.$neutral100,
    layer-02: colors.$neutral95,
    layer-inverse-01: colors.$neutral0,

    // field tokens
    field-01: colors.$neutral90,
    field-hover-01: colors.$neutral80,
    field-inverse-01: colors.$neutral10,
    field-inverse-hover-01: colors.$neutral20,

    // text tokens
    text-primary: colors.$neutral0,
    text-secondary: colors.$neutral30,
    text-placeholder: colors.$neutral60,
    text-on-color: colors.$neutral100,
    text-error: colors.$error40,
    text-inverse: colors.$neutral100,
    text-disabled: colors.$neutral50,

    // field border effects
    field-border-interactive: colors.$info50,

    // link tokens
    link-primary: colors.$info50,
    link-primary-hover: colors.$info30,
    link-primary-active: colors.$info10,

    // border effects
    border-interactive: colors.$info50,
    border-disabled: colors.$neutral80,
    border-strong-01: colors.$neutral50,
    border-inverse: colors.$borderInverse,

    // icon tokens
    icon-primary: colors.$neutral0,
    icon-on-color: colors.$neutral100,
    icon-disabled: colors.$neutral50,

    // overlay tokens - at 70%
    overlay: colors.$neutral10,

    // support tokens
    support-info: colors.$info50,
    support-success: colors.$success50,
    support-warning: colors.$warning50,
    support-error: colors.$error50,
    support-info-inverse: colors.$info50,
    support-success-inverse: colors.$success50,
    support-warning-inverse: colors.$warning50,
    support-error-inverse: colors.$error50,

    // background tokens
    background-inverse: colors.$neutral0,
    background-hover: colors.$neutral90,
  )
);

@use '@carbon/react/scss/components/button/tokens' as button with (
  $button-primary: colors.$info50,
  $button-primary-hover: colors.$info30,
  $button-primary-active: colors.$info20,
  $button-secondary: colors.$neutral0,
  $button-secondary-hover: colors.$neutral20,
  $button-secondary-active: colors.$neutral30,
  $button-disabled: colors.$neutral80
);

@use '@carbon/react/scss/components/notification/tokens' as notification with (
  $notification-background-info: colors.$info95,
  $notification-background-success: colors.$success95,
  $notification-background-warning: colors.$warning95,
  $notification-background-error: colors.$error95
);

@use '@carbon/react';

/**  Components */
@use './components/Header/Header.module.scss';
@use './components/PipelineDistribution/PipelineDistribution.module.scss';
@use './components/BoxGraphWrapper/BoxGraphWrapper.module.scss';
@use './components/BoxGraph/BoxGraph.module.scss';
@use './components/BoxGraphCell/BoxGraphCell.module.scss';
@use './components/DataTileHeader/DataTileHeader.module.scss';
@use './components/DataTile/DataTile.module.scss';
@use './components/DataTileSection/DataTileSection.module.scss';
@use './components/DataTileWrapper/DataTileWrapper.module.scss';
@use './components/DataTileScrolledWrapper/DataTileScrolledWrapper.module.scss';
@use './components/DataTileAccordionHeading/DataTileAccordionHeading.module.scss';

:root {
  @include theme.theme();

  --nav-bar-height: 3.5rem;
  --side-nav-width: 16rem;

  --cds-heading-01-font-size: 1rem;
  --cds-heading-01-font-weight: 400;
  --cds-heading-01-line-height: 1.375rem;
  --cds-heading-01-letter-spacing: 0;

  --cds-heading-02-font-size: 1rem;
  --cds-heading-02-font-weight: 600;
  --cds-heading-02-line-height: 1.375rem;
  --cds-heading-02-letter-spacing: 0;

  --cds-heading-03-font-size: 1.25rem;
  --cds-heading-03-font-weight: 400;
  --cds-heading-03-line-height: 1.75rem;
  --cds-heading-03-letter-spacing: 0;

  --cds-heading-04-font-size: 1.25rem;
  --cds-heading-04-font-weight: 600;
  --cds-heading-04-line-height: 1.75rem;
  --cds-heading-04-letter-spacing: 0;

  --cds-heading-05-font-size: 1.5rem;
  --cds-heading-05-font-weight: 400;
  --cds-heading-05-line-height: 2rem;
  --cds-heading-05-letter-spacing: 0;

  --cds-heading-06-font-size: 1.5rem;
  --cds-heading-06-font-weight: 600;
  --cds-heading-06-line-height: 2rem;
  --cds-heading-06-letter-spacing: 0;

  --cds-body-short-01-font-size: 0.875rem;
  --cds-body-short-01-font-weight: 400;
  --cds-body-short-01-line-height: 1rem;
  --cds-body-short-01-letter-spacing: 0;

  --cds-body-short-02-font-size: 0.875rem;
  --cds-body-short-02-font-weight: 600;
  --cds-body-short-02-line-height: 1rem;
  --cds-body-short-02-letter-spacing: 0;

  --cds-body-long-01-font-size: 0.875rem;
  --cds-body-long-01-font-weight: 400;
  --cds-body-long-01-line-height: 1.25rem;
  --cds-body-long-01-letter-spacing: 0;

  --cds-body-long-02-font-size: 0.875rem;
  --cds-body-long-02-font-weight: 600;
  --cds-body-long-02-line-height: 1.25rem;
  --cds-body-long-02-letter-spacing: 0;

  --cds-label-01-font-size: 0.75rem;
  --cds-label-01-font-weight: 400;
  --cds-label-01-line-height: 1rem;
  --cds-label-01-letter-spacing: 0;

  --cds-label-02-font-size: 0.75rem;
  --cds-label-02-font-weight: 600;
  --cds-label-02-line-height: 1rem;
  --cds-label-02-letter-spacing: 0;
}

input,
textarea,
button {
  font-family: inherit;
}
