@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/spacing' as *;

.data-tile-wrapper {
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  gap: $spacing-03;

  &--desktop {
    flex-flow: row nowrap;
    align-items: flex-start;
  }
}
