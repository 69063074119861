@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;

.data-tile-header {
  flex: 1;
  gap: $spacing-03;
  color: theme.$text-primary;
  padding: $spacing-05;
  background-color: theme.$background;
  min-width: 9rem;
  width: 100%;

  &:hover {
    background-color: theme.$background-hover;
  }

  &__title {
    @include type-style('body-short-02');
    white-space: nowrap;
    flex: 1;

    &--scrolled {
      @include type-style('body-short-02');
    }
  }

  &__content {
    @include type-style('body-01');
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-03;
    flex: 1;

    &--scrolled {
      justify-content: flex-start;
    }
  }
}
