@use '@carbon/react/scss/theme';
@use '@carbon/react/scss/type' as *;
@use '@carbon/react/scss/spacing' as *;

.pipeline-distribution {
  background-color: theme.$layer-01;

  &__title {
    @include type-style('heading-02');
    padding: $spacing-05 $spacing-05 $spacing-03 $spacing-05;
  }
  &__legend {
    display: flex;
    justify-content: flex-start;
    padding: 0 $spacing-05 $spacing-05 $spacing-05;

    &__title {
      display: flex;
      align-items: center;
    }
  }
  &__graphs {
    display: flex;
    flex-wrap: wrap;

    &__wrapper {
      display: flex;
      flex: 1;
      max-width: 59rem;

      & > .box-graph-wrapper {
        flex: 1 1;
      }
    }

    &__mobile {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & > .box-graph-wrapper {
        flex: 1 1;
      }
    }
  }
}

.graph-nav {
  display: flex;
  justify-content: center;

  &__indicators {
    display: flex;
    align-items: center;

    &__indicator {
      display: inline-flex;
    }
  }
}
