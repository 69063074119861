@use '@carbon/react/scss/type' as *;

.data-tile-accordion-heading {
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__title {
    @include type-style('body-short-02');
  }
  &__trend {
    @include type-style('body-short-02');
    align-items: center;
  }
}
